
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";

import { AppContext } from "../context/AppContext";
import encryptedDataF from "../Components/folderx/encryption";
import hashingData from "../Components/folderx/hashing";
import axiosInstance from "../Components/folderx/InterceptorAxios";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "20px",
    marginTop: "20px",
    height: "100%",
    padding: 0,
  },
};

function PaymentStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [transaction, setTransaction] = useState("");
  const [modalIsOpen, setIsOpen] = useState(true);

  const reference_number = localStorage.getItem("reference");
  const { apiendpoints } = useContext(AppContext);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    const fetchData = async () => {
      const { encryptedData, id } = encryptedDataF({});
      const { reqdata } = hashingData({}, id);
      if (!encryptedData || !id || isNaN(id) || !reqdata) {
        toast.error("Sorry! we could not process this request at this moment. Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      const myData = {
        data: encryptedData,
        reqid: id,
        reqdata: reqdata,
      };

      try {
        const response = await axiosInstance.post(apiendpoints?.proAccessToken, myData);
        localStorage.setItem("network_access_token", response.data.response.access_token);

        const access_token = localStorage.getItem("network_access_token");

        getOrderDetails({
          reference: reference_number,
          token: access_token,
        });
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup: set isMounted to false when the component unmounts
    };
  }, []);

  const getOrderDetails = async (data) => {
    const { encryptedData, id } = encryptedDataF(data);
    const { reqdata } = hashingData(data, id);
    if (!encryptedData || !id || isNaN(id) || !reqdata) {
      toast.error("Sorry! we could not process this request at this moment. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const myData = {
      data: encryptedData,
      reqid: id,
      reqdata: reqdata,
    };

    try {
      const res = await axiosInstance.post(apiendpoints?.getOrderDetails, myData);

      const status = res.data;
      if (status === "PURCHASED" || status === "SUCCESS" || status === "ACCEPTED") {
        setTransaction("success");
      } else {
        setTransaction("failed");
      }
      setIsLoading(false);

      const updateData = {
        reference: reference_number,
        status: status,
      };

      setTimeout(async () => {
        const { encryptedData, id } = encryptedDataF(updateData);
        const { reqdata } = hashingData(updateData, id);
        if (!encryptedData || !id || isNaN(id) || !reqdata) {
          toast.error("Sorry! we could not process this request at this moment. Please try again later.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }

        const myData = {
          data: encryptedData,
          reqid: id,
          reqdata: reqdata,
        };

        await axiosInstance.post(apiendpoints?.updateTransactionStatus, myData);

        localStorage.removeItem("reference");
        localStorage.removeItem("token");
        window.top.postMessage(
          {
            message: "message",
            status: status === "PURCHASED" || status === "SUCCESS" || status === "ACCEPTED" ? "success" : "failed",
            data: res.data,
          },
          "*"
        );
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="" ariaHideApp={false}>
      {isLoading ? (
        <div className="top">
          <div className="bodykk">
            <div className="testloader">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      ) : transaction === "success" ? (
        <div className="mainf p-2">
          <div className="card">
            <img src="https://epay-imagess.s3.ap-northeast-2.amazonaws.com/success.gif" loading="lazy" alt="Success" height="200px" width="200px" />
            <h1 className="scc">Success</h1>
            <p>Transaction completed successfully.</p>
          </div>
        </div>
      ) : (
        <div className="mainf p-2">
          <div className="card">
            <img src="https://epay-imagess.s3.ap-northeast-2.amazonaws.com/faild.gif" loading="lazy" alt="Failed" height="200px" width="200px" />
            <h1 className="fail">Failed</h1>
            <p>Your transaction has failed!</p>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default PaymentStatus;
